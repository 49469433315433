var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-form-input-radio-set" },
    _vm._l(_vm.options, function(option, i) {
      return _c("div", { staticClass: "c-form-input-radio-container" }, [
        _c("input", {
          staticClass: "c-form-input c-form-input-radio",
          attrs: { type: "radio", name: _vm.name, id: "radio-" + i },
          domProps: { value: i }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "radio-" + i } }, [
          _c("i", { staticClass: "c-form-icon-radio" }),
          _vm._v("\n            " + _vm._s(option) + "\n        ")
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }