export default class MailchimpSubscription {

  init () {
    function validateEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }

    // NEWSLETTER BLOCK
    var subscribeFormBlock = $('#subscribe-block')
    subscribeFormBlock.submit(function (e) {
      e.preventDefault()
      if (validateEmail($('#email-block').val())) {
        $.ajax({
          type: subscribeFormBlock.attr('method'),
          url: subscribeFormBlock.attr('action'),
          data: subscribeFormBlock.serialize(),
          success: function (data) {
            $('#newsletter-block').html("<p class='success'>Je bent succesvol ingeschreven.</p>")
          },
          error: function (data) {
            console.log('An error occurred.')
          },
        })
      } else {
        $('#newsletter-block .error').html('Gelieve een geldig e-mailadres in te voeren.')
      }
    })

    // FOOTER
    var subscribeForm = $('#subscribe-footer')
    subscribeForm.submit(function (e) {
      e.preventDefault()
      if (validateEmail($('#email-footer').val())) {
        $.ajax({
          type: subscribeForm.attr('method'),
          url: subscribeForm.attr('action'),
          data: subscribeForm.serialize(),
          success: function (data) {
            $('#newsletter-footer').html("<p class='success'>Je bent succesvol ingeschreven.</p>")
          },
          error: function (data) {
            console.log('An error occurred.')
          },
        })
      } else {
        $('#newsletter-footer .error').html('Gelieve een geldig e-mailadres in te voeren.')
      }
    })
  }
}