// Vendors
import $ from "jquery";
import Vue from 'vue';
import slick from 'vue-slick';

//Vue.config.silent = true;

// Components
import navmobile from './components/navmobile.vue';
import icons from './components/icons.vue';
// import alert from './components/alert.vue';
// import tabs from './components/tabs.vue';
// import tab from './components/tab.vue';
// import stickyTop from './components/stickyTop.vue';

// Google Maps
 import googlemaps from './components/gmap.vue';

// Forms
import frmbutton from './components/forms/frmbutton.vue';
import frmcheckbox from './components/forms/frmcheckbox.vue';
import frmdatepicker from './components/forms/frmdatepicker.vue';
import frminput from './components/forms/frminput.vue';
import frmradio from './components/forms/frmradio.vue';
import frmselect from './components/forms/frmselect.vue';
import frmtextarea from './components/forms/frmtextarea.vue';

import MailchimpSubscription from './classes/mailchimpsubscription';

// Other vendor modules
// import RoyalSlider from './classes/royalslider';


class App {

    constructor() {

        window.jQuery = $;
        window.$ = $;

        this.mailchimpsubscription = new MailchimpSubscription();
    }

    init() {

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            components: {

                // Vendors
                'slick': slick,

                // Components
                'navmobile' : navmobile,
                'icon' : icons,

                // Google Maps
                'googlemaps' : googlemaps,

                // Form Elements
                'frmbutton' : frmbutton,
                'frmcheckbox' : frmcheckbox,
                'frmdatepicker' : frmdatepicker,
                'frminput' : frminput,
                'frmradio' : frmradio,
                'frmselect' : frmselect,
                'frmtextarea' : frmtextarea
            },

            data() {

                return {

                    slickOptionsFullWidth: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        lazyLoad: 'ondemand',
                        arrows: false,
                        dots: true,
                        centerMode: false,
                        focusOnSelect: false,
                        infinite: true,
                        accessibility: true,
                        mobileFirst: true,
                        adaptiveHeight: true,
                        autoplay: false,
                        autoplaySpeed: 5000
                    },

                    slickOptionQuotes: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: true,
                        centerMode: false,
                        infinite: true,
                        accessibility: true,
                        mobileFirst: true,
                        prevArrow: '.c-slider-left',
                        nextArrow: '.c-slider-right',
                        autoplay: true,
                        autoplaySpeed: 5000
                    },

                    slickOptionsContent: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: false,
                        centerMode: false,
                        infinite: false,
                        accessibility: true,
                        mobileFirst: true,
                        prevArrow: '.c-slider-left',
                        nextArrow: '.c-slider-right'
                    },
                  
                    slickOptionsMethodsContent: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: false,
                        centerMode: false,
                        infinite: false,
                        accessibility: true,
                        mobileFirst: true,
                        prevArrow: '.js-slider-methods-left',
                        nextArrow: '.js-slider-methods-right'
                    },
                    
                    slickOptionsUspContent: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: false,
                        centerMode: false,
                        infinite: false,
                        accessibility: true,
                        mobileFirst: true,
                        prevArrow: '.js-slider-usp-left',
                        nextArrow: '.js-slider-usp-right'
                    },

                    slickOptionsImageSlider: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        lazyLoad: 'ondemand',
                        arrows: false,
                        dots: true,
                        centerMode: false,
                        infinite: false,
                        accessibility: true,
                        mobileFirst: true
                    }
                }
            },

            methods: {

                handleInit(event, slick) {

                    if ($('.c-slider-slick-content').length > 0) {

                        $('.c-slider-slick-content').on('afterChange', function(event, slick, currentSlide, nextSlide){

                            $('.c-slider-slick-counter').html(currentSlide + 1);
                        });
                    }
                }, 
                handleInitMethods(event, slick) {

                    if ($('.js-slider-methods').length > 0) {

                        $('.js-slider-methods').on('afterChange', function(event, slick, currentSlide, nextSlide){

                            $('.js-slider-methods-counter').html(currentSlide + 1);
                        });
                    }
                },
                handleInitUsps(event, slick) {

                    if ($('.js-slider-usp').length > 0) {

                        $('.js-slider-usp').on('afterChange', function(event, slick, currentSlide, nextSlide){

                            $('.js-slider-usp-counter').html(currentSlide + 1);
                        });
                    }
                }
            }
        });
        this.mailchimpsubscription.init();
    }
}

$(() => {

    const app = new App();

    app.init();

});