var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "c-icon", class: "c-icon-" + _vm.id }, [
    _c("use", {
      attrs: { "xlink:href": "/_prototype/dist/sprite/sprite.svg#" + _vm.id }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }